/* eslint-disable no-loop-func */
import React, { useEffect, useState } from "react";
import Details from "../../components/Details/Details";
import Inventory from "../../components/Inventory/Inventory";
import Status from "../../components/Status/Status";
import Podstatus from "../../components/Podstatus/Podstatus";
import Content from "../../components/Content/Content";
import Eventlog from "../../components/EventLog/Eventlog";
import TotalUtilizationSummary from "../../components/TotalUtilizationSummary/TotalUtilizationSummary";

import { Grid, Box } from "grommet/components";
import { Col, Divider, Row, Tooltip } from "antd";

import { Button, Modal, Steps } from "antd";
import EdgeSettingsPage from "../EdgeSettingsPage";
import { useDispatch, useSelector } from "react-redux";
import {
  confirmDialouge,
  isEdgeUpdateAvailable,
  setEdgeAppDetails,
  setEdgeDetails,
  setInventory,
  showEdgeSettings,
} from "../../reduxStore/actions";
import { apiEndPoints } from "../../constants/ApiEndPoints";
import Axios from "axios";

import PodsSummary from "../../components/PodsSummary/PodsSummary";

import ConfigurationError from "../../components/ConfigurationError/ConfigurationError";
import UpdateInstallEdge from "../../components/UpdateInstallEdge/UpdateInstallEdge";
import {
  GeneralConstants,
  General_color,
} from "../../constants/GeneralConstants";
import { generateTime } from "../../constants/commonFunction";
import Actions from "../../components/Actions/actions";
import { useHistory } from "react-router-dom";

function EdgeDetailsComponents({ edgeName, edgeId, platformType, location }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const isDeploying = useSelector((state) => state.setIsEdgeDeploying);
  const apiReloader = useSelector((state) => state.setApiReloader);
  const showSettingModal = useSelector((state) => state.showEdgeSettings);
  const matricsData_ = useSelector((state) => state.setData.data);
  const [totalRam, setTotalram] = useState();
  const AllApplicationSummary = useSelector(
    (state) => state.setEdgeStatus.data
  );
  useEffect(() => {
    const dataHere = AllApplicationSummary?.edgeServiceMetricsSummary;
    const fullRam =
      dataHere?.applicationRamTotal +
      dataHere?.availableRAM +
      dataHere?.othersRAM +
      dataHere?.platformRamTotal;
    setTotalram(fullRam);
  }, [AllApplicationSummary]);
  const Pod_Inventorycounts = useSelector(
    (state) =>
      state.setEdgeStatus?.data?.edgeServiceMetricsSummary
        ?.edgeApplicationPodMetrics
  );
  useEffect(() => {
    if (!edgeName || !edgeId || !location) {
      history.push("/");
    }
  }, [edgeName, edgeId, location, history]);

  const pods = useSelector((state) => state.setEdgeStatus);
  const [allPods, setAllPods] = useState([]);

  const edgeDetails_metrics = useSelector((state) => state.setEdgeStatus.data);
  const [serviceNamePodCount, setServiceNamePodCount] = useState(0);
  const [runningStatusCount, setRunningStatusCount] = useState(0);
  const [errorStatusCount, setErrorStatusCount] = useState(0);
  const [pendingStatusCount, setPendingStatusCount] = useState(0);
  const [appsrunningCount, setappsrunningCount] = useState(0);
  const [appserrorCount, setappserrorCount] = useState(0);
  const [appspendingCount, setappspendingCount] = useState(0);
  const [sudo_inventoryData, setsudo_inventoryData] = useState([]);
  const [openNotification, setOpenNotification] = useState(false);
  const [cpuInventory, setcpuInventory] = useState(0);
  const [ramInventory, setramInventory] = useState(0);

  useEffect(() => {
    if (!pods) return;
    let totalPods = [];
    pods?.data?.edgeServiceMetricsSummary?.edgeApplicationPodMetrics?.map(
      (item) => {
        totalPods.push(...item.edgePodStatisticsList);
      }
    );
    setAllPods(totalPods);
  }, [pods]);

  useEffect(() => {
    if (Pod_Inventorycounts && Pod_Inventorycounts.length > 0) {
      const filteredPods = Pod_Inventorycounts.filter(
        (app) => app.isPlatform == true
      );

      const filterApps = Pod_Inventorycounts.filter(
        (app) => app.isPlatform == false
      );
      let runningCount = 0;
      let errorCount = 0;
      let pendingCount = 0;
      let PodCount = 0;
      let appsRunning = 0;
      let appsPending = 0;
      let appsError = 0;

      filteredPods.forEach((application) => {
        PodCount += application.edgePodStatisticsList.length;

        application.edgePodStatisticsList.forEach((data) => {
          if (data.statusPod === "Running") {
            runningCount++;
          } else if (data.statusPod === "Error") {
            errorCount++;
          } else if (data.statusPod === "Pending") {
            pendingCount++;
          }
        });
      });

      filterApps.forEach((app) => {
        // console.log(app)
        // appsRunning++;
        app?.edgePodStatisticsList?.forEach((pod, i) => {
          // console.log(i,"----",pod)
          if (pod.statusPod === "Running") {
            appsRunning++;
          } else if (pod.statusPod === "Pending") {
            appsPending++;
            // appsRunning--;
          } else if (pod.statusPod === "Error") {
            appsError++;
          }
        });
      });
      console.log(appsRunning, appsPending, appsError);
      setServiceNamePodCount(PodCount);
      setRunningStatusCount(runningCount);
      setErrorStatusCount(errorCount);
      setPendingStatusCount(pendingCount);
      setappsrunningCount(appsRunning);
      setappspendingCount(appsPending);
      setappserrorCount(appsError);
      if (edgeDetails_metrics?.edgeServiceMetricsSummary?.nodeRAM != null) {
        setramInventory(
          edgeDetails_metrics?.edgeServiceMetricsSummary?.nodeRAM +
            edgeDetails_metrics?.edgeServiceMetricsSummary?.availableRAM
        );
      }
      if (edgeDetails_metrics?.edgeServiceMetricsSummary?.nodeCPU != null) {
        setcpuInventory(
          edgeDetails_metrics?.edgeServiceMetricsSummary?.nodeCPU
        );
      }
      // if (sudo_inventoryData?.applicationDetails) {
      setTimeout(() => {
        seInventoryData_redux(sudo_inventoryData);
      }, 3000);
      // }
    }
  }, [Pod_Inventorycounts, sudo_inventoryData]);

  // const edgeDetails = useSelector(state=>state.setEdgeDetails?.edgeDetails?.[0]);
  const [currentStatus, setCurrentStatus] = useState("");
  let [count, setCount] = useState(0);
  const [progressNumber, setProgressNumber] = useState(0);

  const [detailsData, setDetailsData] = useState([
    {
      heading: "Name",
      detail: "EN",
    },
    {
      heading: "ID",
      detail: edgeId,
    },
    {
      heading: "Location",
      detail: location,
    },
    { heading: "Connectivity status", detail: platformType },
    {
      heading: "Cluster Version",
      detail: "-",
      italic: true,
    },
  ]);
  const [sudoState, setSudoState] = useState(0);

  useEffect(() => {
    if (platformType === "unknown") {
      dispatch(showEdgeSettings({ payload: true }));
    }
    dispatch(confirmDialouge({ payload: false }));
  }, [platformType]);

  useEffect(() => {
    // },[])

    // useEffect(()=>{
    if (isDeploying) {
      return;
    }
    let fetchEdgeDetails = apiEndPoints.FETCH_SINGLE_EDGE_DETAILS.replace(
      "*edgeId*",
      edgeId
    );

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: fetchEdgeDetails,
    };
    Axios.request(config)
      .then((response) => {
        // dispatch(setEdgeDetails({ payload: response.data }));
        // console.log(response.data)
        // setTimeout(()=>{
        localStorage.setItem(
          "edgeName",
          response?.data?.edgeDetails?.[0]?.name
        );
        settingDetailsData(response.data);
        setsudo_inventoryData(response.data);
        setTimeout(() => {
          seInventoryData_redux(response.data);
        }, 3000);
        dispatch(
          setEdgeAppDetails({
            payload: {
              data: response.data,
              isLoading: false,
            },
          })
        );
        // },100)

        setSudoState((prev) => prev + 1);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [apiReloader]);

  const settingDetailsData = (data) => {
    let edgeDetails = data?.edgeDetails?.[0];
    dispatch(
      setEdgeDetails({
        payload: {
          data: [
            {
              heading: "Name",
              detail: edgeDetails?.name,
            },
            {
              heading: "ID",
              detail: edgeDetails?.edgeId,
            },
            {
              heading: "Location",
              detail: edgeDetails?.location,
            },
            { heading: "Edge Role", detail: edgeDetails?.edgeType },
            {
              heading: "Connectivity status",
              detail: edgeDetails?.platformType,
            },
            // {
            //   heading: "Software Update",
            //   detail: "-",
            //   link: `/softwareupdate?edgeId=${edgeDetails?.edgeId}`,
            // },
          ],
          isLoading: false,
        },
      })
    );
    // console.log("edge data", )
  };

  const seInventoryData_redux = (data) => {
    let inventoryData = data?.applicationDetails;
    console.log(
      `RAM ${
        (edgeDetails_metrics?.edgeServiceMetricsSummary?.nodeRAM == undefined
          ? 0
          : edgeDetails_metrics?.edgeServiceMetricsSummary?.nodeRAM +
              edgeDetails_metrics?.edgeServiceMetricsSummary?.availableRAM ==
            undefined
          ? 0
          : edgeDetails_metrics?.edgeServiceMetricsSummary?.availableRAM
        ).toFixed(2) + " GB"
      } `
    );
    let Ram = (
      edgeDetails_metrics?.edgeServiceMetricsSummary?.nodeRAM +
      edgeDetails_metrics?.edgeServiceMetricsSummary?.availableRAM
    ).toFixed(2);
    let cpu =
      matricsData_?.[0]?.data?.[0]?.v == undefined
        ? 0
        : matricsData_?.[0]?.data?.[0]?.v + " core";

    dispatch(
      setInventory({
        payload: {
          data: [
            // {
            //   name: "01 Node",
            // },
            {
              name: `Applications`,
              details: `${
                inventoryData.appsInUse == 0
                  ? " "
                  : `${inventoryData.appsInUse}/${inventoryData.appsAvailable} installed`
              } `,
              warningstatus: [
                { icon: "good", value: appsrunningCount },
                { icon: "medium", value: appspendingCount },
                { icon: "critical", value: appserrorCount },
              ],
              route: `/allapplications?edgeId=${edgeId}`,
              isEnabled: `${
                platformType == "unknown" || allPods.length == 0 ? false : true
              }`,
            },
            {
              name: `Platform`,
              // name: "24 Pod(s)",
              // details: "of 50 Limit",
              warningstatus: [
                { icon: "good", value: runningStatusCount },
                { icon: "medium", value: pendingStatusCount },
                { icon: "critical", value: errorStatusCount },
              ],
            },
            {
              name: `RAM ${
                totalRam.toFixed(1) == undefined
                  ? 0
                  : totalRam.toFixed(1) + " GB"
              } `,
              // details: "0f 100 Limit",
              // warningstatus: [{ icon: "medium", value: "1" }],
            },
            {
              // name: `${cpuInventory?.toFixed(2)} % CPU`,
              name: `CPU ${
                matricsData_?.[0]?.data?.[0]?.v == undefined
                  ? 0
                  : matricsData_?.[0]?.data?.[0]?.v + " core"
              } `,
            },
          ],
          isLoading: false,
        },
      })
    );
  };
  const setModal2Open = () => {
    dispatch(showEdgeSettings({ payload: false }));
  };

  return (
    <>
      <Modal
        centered
        open={showSettingModal}
        onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
        width={900}
      >
        <EdgeSettingsPage />
      </Modal>

      <Row style={{ gridGap: "10px", paddingRight: "20px" }}>
        <Col flex="1 1 250px" style={{ display: "grid", rowGap: "10px" }}>
          <Row style={{ borderRadius: "10px", height: "45vh" }}>
            <Col
              className="gridContainer"
              style={{ width: "100%", height: "-webkit-fill-available" }}
            >
              <Details isSetting={true} />
            </Col>
          </Row>
          <Row
            style={{
              // background: "pink",
              borderRadius: "10px",
              height: "45vh",
            }}
          >
            <Col
              style={{ width: "100%", height: "-webkit-fill-available" }}
              className="gridContainer"
            >
              {/* {platformType == "unknown" ? (
                <ConfigurationError />
              ) : ( */}
              <Inventory />
              {/* )} */}
            </Col>
          </Row>
        </Col>

        <Col
          flex="1 1 730px"
          style={{
            columnGap: "10px",
            // background: "pink",
            display: "grid",
            rowGap: "10px",
          }}
        >
          <Row style={{ gridGap: "10px" }}>
            <Col
              className="gridContainer"
              flex="1"
              style={{
                // background: "magenta",
                borderRadius: "10px",
                // padding: "30px",
                height: "9vh",
              }}
            >
              <Status headingVisible="true" />
            </Col>
          </Row>
          <Row style={{ gridGap: "10px" }}>
            <Col
              className="gridContainer"
              flex="1"
              style={{
                // background: "magenta",
                borderRadius: "10px",
                // padding: "30px",
                height: "35vh",
              }}
            >
              {platformType == "unknown" ? (
                <ConfigurationError />
              ) : (
                <>
                  <TotalUtilizationSummary />
                </>
              )}
            </Col>
          </Row>
          <Row style={{ gridGap: "10px" }}>
            <Col
              className="gridContainer"
              flex="1"
              style={{
                // background: "blue",
                borderRadius: "10px",
                // padding: "90px",
                height: "45vh",
              }}
            >
              {/* {platformType == "unknown" ? (
                <ConfigurationError />
              ) : (
                <> */}
              <PodsSummary />
              <Podstatus headingVisible="true" />
              {/* </>
              )} */}
            </Col>
          </Row>
        </Col>

        <Col
          flex="1 1 250px"
          style={{ borderRadius: "10px", rowGap: "10px", display: "grid" }}
        >
          <Row>
            <Col
              className="gridContainer"
              style={{ height: "60vh", flex: "1" }}
            >
              {/* {platformType == "unknown" ? (
                <ConfigurationError />
              ) : (
                <> */}
              <Content />
              {/* </>
              )} */}
              {/* asdf */}
            </Col>
          </Row>
          <Row>
            <Col
              className="gridContainer"
              style={{ height: "30vh", flex: "1" }}
            >
              {/* {platformType == "unknown" ? (
                <ConfigurationError />
              ) : (
                <> */}
              <Actions />
              {/* </>
              )} */}
              {/* asdf */}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default EdgeDetailsComponents;
